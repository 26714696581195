import {
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { SaveButton, useNotify, useTranslate } from "react-admin";
import columns from "./columns";

export const useStyles = makeStyles((theme) => ({
  toolBar: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: 65,
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(3),
    backgroundColor: theme.palette.primary.light,
    marginTop: theme.spacing(8),
  },
  form: {
    marginLeft: theme.spacing(9),
    marginTop: theme.spacing(6),
    border: " 1px solid #0AA5B7",
    borderRadius: "5px",
    "& .MuiCardContent-root": {
      padding: theme.spacing(8),
    },
  },
  root: {
    fontSize: "1.25rem",
    width: "fit-content",
    border: "1px solid #0AA5B7",
    color: "rgba(0, 0, 0, 0.5)",
  },
  tableHeader: {
    color: theme.palette.text.secondary,
    paddingInline: theme.spacing(5),
    fontSize: "1.6rem",
    fontWeight: "bold",
    border: " 1px solid #0AA5B7",
    backgroundColor: "#C4E4E8",
    textTransform: "capitalize",
    verticalAlign: "top",
  },
  inputMedium: {
    maxWidth: 400,
    width: "100%",
    backgroundColor: theme.palette.primary.light,
    marginRight: theme.spacing(2),
    color: "rgba(0, 0, 0, 0.45)",
    textAlign: "left",
    padding: theme.spacing(1.5),
    fontSize: "1.25rem",
  },
}));

const EditPayoutMethodForm = () => {
  const classes = useStyles();
  const [payoutPricings, setPayoutPricings] = useState([
    {
      id: null,
      pricingId: null,
      name: "Empty preset",
    },
  ]);
  const notify = useNotify();
  const translate = useTranslate();
  const [payoutMethods, setPayoutMethods] = useState([
    {
      type: "test",
      paymentProviderEnabled: true
    },
  ]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const data = [];

  return (
    <>
      <Grid container direction="row" justifyContent="center">
        <Table className={classes.root}>
          <TableHead>
            <TableRow className={classes.tableHeader}>
              {columns.map((column) => (
                <TableCell
                  classes={{ root: classes.tableHeader }}
                  key={column.id}
                  align={column.align}
                >
                  {translate(column.label)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {payoutMethods?.map((row, idx) => (
              <TableRow key={idx}>
                <TableCell
                  classes={{
                    root: classes.root,
                  }}
                  align="center"
                  component="th"
                  scope="row"
                >
                  {row.type}
                </TableCell>
                <TableCell
                  classes={{
                    root: classes.root,
                  }}
                  align="center"
                >
                  {row.type}
                </TableCell>
                <TableCell
                  classes={{
                    root: classes.root,
                  }}
                  align="center"
                  component="th"
                  scope="row"
                >
                  {row.type}
                </TableCell>
                <TableCell
                  classes={{
                    root: classes.root,
                  }}
                  align="center"
                  component="th"
                  scope="row"
                >
                  {row.type}
                </TableCell>
                <TableCell
                  classes={{
                    root: classes.root,
                  }}
                  align="center"
                  component="th"
                  scope="row"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={row?.paymentProviderEnabled}
                        onChange={(e) => {
                          data.push({
                            ...row,
                            paymentProviderEnabled:
                              !row?.paymentProviderEnabled,
                          });
                        }}
                      />
                    }
                    label={
                      row?.paymentProviderEnabled
                        ? `${translate(
                            "resources.merchants.forms.payments.enable"
                          )}`
                        : `${translate(
                            "resources.merchants.forms.payments.disable"
                          )}`
                    }
                    onChange={(e) => {
                      data.push({
                        ...row,
                        paymentProviderEnabled: !row?.paymentProviderEnabled,
                      });
                    }}
                  />
                </TableCell>
                <TableCell
                  classes={{
                    root: classes.root,
                  }}
                  align="center"
                  component="th"
                  scope="row"
                >
                  <Select
                    id={"id"}
                    label={false}
                    className={classes.inputMedium}
                    defaultValue={"default"}
                    onChange={(e) => {
                      data.push({
                        ...row,
                        specialPriceId: { uuid: e.target.value.pricingId },
                      });
                    }}
                  >
                    {payoutPricings?.map((i) => (
                      <MenuItem id={i.id} value={i}>
                        {i.name}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
      <Grid className={classes.toolBar}>
        <SaveButton handleSubmitWithRedirect={() => handleSubmit()}>
          Submit
        </SaveButton>
      </Grid>
    </>
  );
};

export default EditPayoutMethodForm;
