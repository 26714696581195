import {createTheme} from "@material-ui/core/styles";
import typography from "../../../styles/typography";
import palette from "../../../styles/palette";
import {theme} from "../../../styles/theme";


export const themeMUI = () => {
    return createTheme({
            typography: typography,
            palette: palette,
            overrides: {
                MuiPaper: {
                    root: {
                        boxShadow: 'none',
                    },
                    rounded: {
                        borderRadius: '15px 15px 0 0',
                    },
                    elevation1: {
                        boxShadow: 'none',
                        borderLeft: "1px solid #0AA5B7",
                        borderRight: "1px solid #0AA5B7",
                        borderTop: "1px solid #0AA5B7",
                        borderBottom: "1px solid #0AA5B7",
                    },
                },
                RaListToolbar: {
                    toolbar: {
                        marginTop: theme.spacing(6)
                    }
                },
                MuiTableSortLabel: {
                    icon: {
                        color: `${theme.palette.background.default} !important`
                    },
                    root: {
                        '&:hover': {
                            color: "#FDC300",
                        },
                    },

                    active: {
                        color: `${theme.palette.background.default} !important`,
                    }

                },
                MuiTableCell: {
                    head: {
                        backgroundColor: `${theme.palette.primary.main} !important`,
                        fontWeight: 700,
                        borderBottom: "1px solid #0AA5B7",
                        color: theme.palette.background.default,
                        textTransform: "capitalize",
                    },
                    body: {
                        fontWeight: 400,
                        fontSize: 14,
                    },
                    root: {
                        borderBottom: "1px solid #0AA5B7",
                        paddingTop: 0,
                        paddingBottom: 0,
                    },
                    sizeSmall: {
                        "&:last-child": {
                            borderTopRightRadius: "15px !important",
                        },
                        "&:first-child": {
                            borderTopLeftRadius: "15px !important",
                        },
                    }
                }
                ,
                MuiCheckbox: {
                    root: {
                        display: "none"
                    }
                },
                MuiIconButton: {
                    label: {
                        color: theme.palette.primary.main,
                    }
                },
                MuiFilledInput: {
                    root: {
                        backgroundColor: theme.palette.primary.light,
                        color: theme.palette.primary.main,
                    }
                },
                MuiButton: {
                    root: {
                        borderRadius: 20,
                        border: "0.5px solid #0AA5B7",
                        marginRight: theme.spacing(1),
                        padding: theme.spacing(0, 1)
                    },
                    textSizeSmall: {
                        padding: theme.spacing(0.5, 1.2)
                    }
                }
            },

        }
    );
};
