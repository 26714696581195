import * as React from 'react';
import {
    Create,
    email,
    maxLength,
    minLength,
    number,
    PasswordInput,
    RadioButtonGroupInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput,
    useNotify,
    useRedirect,
    useTranslate
} from 'react-admin';
import {Box, Grid} from "@material-ui/core";
import {SectionTitle} from "../../components/SectionTitle";
import {useStyles} from "./styles";
import {allCountries, supportedCurrencies} from "../../helpers/choices";
import {CustomToolbar} from "../../components/CustomToolbar";


const validateGlobal = (values) => {
    const errors = {};
    if (values?.password !== values?.confirm_password) {
        errors.confirm_password = 'resources.register.passwordMatchError';
    }
    if (values?.userLogin && values.userLogin.toLowerCase() !== values.userLogin) {
        errors.userLogin = 'resources.register.usernameError';
    }
    return errors
};
const passwordValidate = [required(), minLength(6), maxLength(100)];
const nameValidate = [required(), maxLength(50)];
const usernameValidate = [required(), minLength(5), maxLength(50)];
const addressValidate = [required(), maxLength(100)];
const telephoneValidate = [required(), number(), minLength(6), maxLength(20)];
const requiredValidate = [required()];

const CreateForm = (props) => {

    const classes = useStyles();
    const notify = useNotify()
    const redirect = useRedirect();
    const translate = useTranslate()

    const onFailure = (resp) => {
        if (resp.status === 400) {
            let message = resp?.body?.fieldErrors?.map(i => i.field.toUpperCase())

            message.map(i => {
                if (i === "TELEPHONE") {
                    notify("resources.register.phoneError", {type: 'warning'})
                }
                if (i === "USERLOGIN") {
                    notify("resources.register.userLoginError", {type: 'warning'})
                }
                if (i === "LOGIN") {
                    notify("resources.register.userLoginInUseError", {type: 'warning'})
                }
                if (i === "EMAIL") {
                    notify("resources.register.emailInUseError", {type: 'warning'})
                }
                if (i === "COMPANY") {
                    notify("resources.register.companyNameInUseError", {type: 'warning'})
                }

            })
        } else {
            notify('ra.notification.http_error', {type: 'warning'});
        }
    }
    const onSuccess = (resp) => {

        if (resp.status === 201) {
            notify('ra.notification.created', {type: 'success'});
            redirect('/registration-saved');
        }
    }

    return (
        <Create
            classes={{
                card: classes.paper,
            }}
            redirect={false}
            onFailure={onFailure}
            onSuccess={onSuccess}
            resource='register'
            title={'resources.register.title'}
            {...props}
            {...props}
            basePath="/create-profile"
            {...props} >
            <SimpleForm
                validate={validateGlobal}
                toolbar={<CustomToolbar handleSubmitWithRedirect={'list'} label='resources.register.submitButton'/>}>
                <Grid className={classes.titleForm}>
                    {translate('resources.register.title')}
                </Grid>
                <Grid container className={classes.root} direction='column' justifyContent='space-between'>
                    <SectionTitle
                        className={classes.title}
                        label="resources.register.basicInfo"/>
                    <Box className={classes.wrapper}>
                        <Grid className={classes.sectionWrapper}>
                            <TextInput
                                resource={'register'}
                                source="contactPersonFirstName"
                                placeholder="Enter your first name here"
                                className={classes.inputMedium}
                                validate={nameValidate}
                            />
                            <TextInput
                                resource={'register'}
                                placeholder="Enter your last name here"
                                source="contactPersonLastName"
                                className={classes.inputMedium}
                                validate={nameValidate}
                            />
                            <TextInput
                                resource={'register'}
                                source="userLogin"
                                placeholder="Enter your username here"
                                className={classes.inputMedium}
                                validate={usernameValidate}
                            />
                        </Grid>
                        <Grid justifyContent="flex-end" className={classes.sectionWrapper}>
                            <PasswordInput
                                resource={'register'}
                                source="password"
                                className={classes.password}
                                validate={passwordValidate}
                            />
                            <PasswordInput
                                resource={'register'}
                                label="resources.register.fields.confirmPassword"
                                source="confirm_password"
                                className={classes.password}
                                validate={passwordValidate}
                            />
                        </Grid>
                    </Box>

                    <SectionTitle
                        className={classes.title}
                        label="resources.register.companyInfo"/>
                    <Box className={classes.wrapper}>
                        <TextInput
                            resource={'register'}
                            source="companyName"
                            style={{marginRight: 16}}
                            className={classes.inputMedium}
                            validate={requiredValidate}
                        />
                        <TextInput
                            resource={'register'}
                            source="address.address"
                            className={classes.inputMedium}
                            validate={addressValidate}
                        />
                    </Box>
                    <Box className={classes.wrapper}>
                        <Box width={'50%'} mr={2}>
                            <TextInput
                                resource={'register'}
                                source="address.state"
                                className={classes.inputMedium}
                                validate={addressValidate}
                            />
                        </Box>
                        <Box className={classes.fieldWrapper}>
                            <TextInput
                                style={{marginRight: 16}}
                                resource={'register'}
                                source="address.city"
                                margin={'dense'}
                                className={classes.inputMedium}
                                validate={addressValidate}
                            />
                            <TextInput
                                resource={'register'}
                                source="address.postalCode"
                                margin={'dense'}
                                className={classes.inputMedium}
                                validate={[
                                    required(),
                                    minLength(5),
                                    maxLength(10)]}/>
                        </Box>
                    </Box>
                    <Box className={classes.fieldWrapper}>
                        <SelectInput
                            resource={'register'}
                            source="address.countryIso3Code"
                            style={{marginRight: 16}}
                            className={classes.inputMedium}
                            choices={allCountries}
                            optionText="name"
                            optionValue="code"
                            validate={requiredValidate}
                        />
                        <SelectInput
                            resource={'register'}
                            source="currency"
                            style={{marginRight: 8}}
                            className={classes.inputMedium}
                            choices={supportedCurrencies}
                            optionText="fullDescription"
                            optionValue="code"
                            validate={requiredValidate}
                        />
                    </Box>
                    <SectionTitle
                        className={classes.title}
                        label="resources.register.contactInfo"/>
                    <Box className={classes.wrapper}>
                        <TextInput
                            style={{marginRight: 16}}
                            label="resources.register.fields.phoneNumber"
                            source="telephone"
                            className={classes.inputMedium}
                            validate={telephoneValidate}
                        />
                        <TextInput
                            resource={'register'}
                            source="faxNumber"
                            className={classes.inputMedium}
                        />
                    </Box>
                    <Box className={classes.fieldWrapper}>
                        <TextInput
                            style={{marginRight: 8}}
                            resource={'register'}
                            type="email"
                            source="email"
                            validation={{email: true}}
                            className={classes.inputMedium}
                            validate={[required(),
                                email(),
                                minLength(5),
                                maxLength(100)]}
                        />
                    </Box>
                    <RadioButtonGroupInput
                        resource={'register'}
                        source="receiveNewsletter"
                        choices={[
                            {checked: true, name: 'resources.register.fields.yes'},
                            {checked: false, name: 'resources.register.fields.no'},
                        ]}
                        optionText="name"
                        optionValue="checked"
                    />
                </Grid>
            </SimpleForm>
        </Create>
    )
};
export default CreateForm;