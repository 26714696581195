import * as React from 'react';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import classnames from 'classnames';
import {makeStyles} from '@material-ui/core/styles';
import {MenuItemLink, ReduxState, usePermissions, useTranslate} from 'react-admin';
import PeopleIcon from '@material-ui/icons/People';
import DescriptionIcon from '@material-ui/icons/Description';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

import orders from '../views/orders/index';
import SubMenu from "./SubMenu";


const Menu = ({dense = false}) => {
    const [state, setState] = useState({
        menuCommissions: false,
    });
    const translate = useTranslate();
    const {permissions} = usePermissions()

    const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
    const classes = useStyles();
    const handleToggle = (menu) => {
        setState(state => ({...state, [menu]: !state[menu]}));
    };


    return (
        <div
            className={classnames(classes.root, {
                [classes.open]: open,
                [classes.closed]: !open,
            })}
        >
            {' '}

            {( permissions === 'ROLE_BUSINESS_ADMIN' || permissions === 'ROLE_MERCHANT') &&  <MenuItemLink
                className={classnames(classes.asideLink, {
                    [classes.iconOpen]: open,
                    [classes.iconClosed]: !open,
                })}
                to={{
                    pathname: '/order/report',
                    state: {_scrollToTop: true},
                }}
                primaryText={translate("sideBarTitles.transactions")}
                leftIcon={<orders.icon className={classnames({
                    [classes.iconOpen]: open,
                    [classes.iconClosed]: !open,
                })}/>}
                dense={dense}
            />}
            {permissions === 'ROLE_BUSINESS_ADMIN' && <MenuItemLink
                className={classnames(classes.asideLink, {
                    [classes.iconOpen]: open,
                    [classes.iconClosed]: !open,
                })}
                to="/merchants"
                primaryText={translate("sideBarTitles.merchants")}
                leftIcon={
                    <PeopleIcon className={classnames({
                        [classes.iconOpen]: open,
                        [classes.iconClosed]: !open,
                    })}/>}/>}
            {permissions === 'ROLE_BUSINESS_ADMIN' && < SubMenu
                className={classnames(classes.asideLink, {
                    [classes.iconOpen]: open,
                    [classes.iconClosed]: !open,
                })}
                handleToggle={() => handleToggle('menuCommissions')}
                isOpen={state.menuCommissions}
                name={translate("sideBarTitles.commissions")}
                icon={<AttachMoneyIcon className={classnames({
                    [classes.iconOpen]: open,
                    [classes.iconClosed]: !open,
                })}/>}
                dense={dense}
            >
                <MenuItemLink
                    style={{marginLeft: '1.8rem'}}
                    className={classnames(classes.asideLink, {
                        [classes.iconOpen]: open,
                        [classes.iconClosed]: !open,
                    })}
                    to={{
                        pathname: '/payments',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate("sideBarTitles.payment")}
                    dense={dense}
                />
                <MenuItemLink
                    className={classnames(classes.asideLink, {
                        [classes.iconOpen]: open,
                        [classes.iconClosed]: !open,
                    })}
                    style={{marginLeft: '1.8rem'}}
                    to={{
                        pathname: '/payout',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate("sideBarTitles.payout")}
                    dense={dense}
                />
            </SubMenu>}

            {(permissions === 'ROLE_BUSINESS_ADMIN' || permissions === 'ROLE_MERCHANT') &&  <MenuItemLink
                className={classnames(classes.asideLink, {
                    [classes.iconOpen]: open,
                    [classes.iconClosed]: !open,
                })}
                to={{
                    pathname: '/payouts',
                    state: {_scrollToTop: true},
                }}
                primaryText={translate("sideBarTitles.payouts")}
                leftIcon={<DescriptionIcon className={classnames({
                    [classes.iconOpen]: open,
                    [classes.iconClosed]: !open,
                })}/>}
                dense={dense}
            />}

        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    open: {
        width: 300,
        color: theme.palette.primary.main
    },
    closed: {
        backgroundColor: theme.palette.primary.main,
        height: "100%",
        width: 75,
    },
    iconClosed: {
        color: theme.palette.background.default,
        fontWeight: "bold !important",
        fontSize: "1.25rem !important",
    },
    iconOpen: {
        color: `${theme.palette.primary.main} !important`,
        fontWeight: "bold !important",
        fontSize: "1.25rem !important"
    },
    asideLink: {
        color: theme.palette.primary.main,
        "& .MuiMenuItem-root": {
            paddingTop: theme.spacing(4),
        }

    }
}));

export default Menu;
