import { TranslationMessages } from "react-admin";
import frenchMessages from "ra-language-french";

const customFrenchMessages: TranslationMessages = {
    ...frenchMessages,
    show: "Voir",
    infoPage: {
        accountUpdated: "Compte mis à jour ! ",
        checkEmail:
            "Vérifiez votre e-mail pour définir un nouveau mot de passe",
        registred: "Inscription enregistrée ! ",
        checkEmailApproved:
            "Vous recevrez un email après l’activation de votre compte",
    },
    resetPasswordForm: {
        title: "Réinitialiser votre mot de passe",
        text: "Veuillez entrer l’adresse email utilisée pour votre inscription",
        email: "E-mail",
        submit: "RÉINITIALISER",
    },
    activationNote: {
        title: "Activation",
        success:
            "Le compte du marchand a été activé. Un mail lui a été envoyé pour se connecter.",
        error: "Le compte du marchand n'a pas pu être activé. Utilisez le formulaire d'enregistrement pour en créer un nouveau. ",
    },
    retypePasswordForm: {
        title: "Réinitialisation du mot de passe ",
        newPassword: "Mot de passe",
        text: "Choisir un nouveau mot de passe",
        placeholderEnterYourPassword:
            " Entrez votre nouveau mot de passe ici...",
        placeholderConfirmYourPassword:
            " Confirmation du nouveau mot de passe...",
        retypePassword: "Confirmation du nouveau mot de passe",
        submit: "Réinitialiser",
    },
    resetPasswordApply: {
        title: "Réinitialiser votre mot de passe",
        success:
            "otre mot de passe a été réinitialisé. Merci de vous authentifier",
        error: "Votre mot de passe n'a pas pu être réinitialisé. La demande de réinitialisation est valide pour 24 heures.",
    },
    title: {
        edit: "MODIFIER",
    },
    loginPage: {
        stayLoggedInLabel: "Rester connecté",
        createAccountButton: "Créer un compte",
        forgotPasswordButton: "Avez-vous oublié votre mot de passe ?",
    },
    apiForm: {
        apiSettings: "Configuration API",
        text: "Vous pouvez gérer votre compte API directement dans le",
        link: " gestionnaire d’API.",
        sectionTitle: "URLs de rappel",
        fieldReturnUrl: "URL de retour",
        fieldInstantNoteUrl: "URL de notification instantanée",
        toggleGet: "Utilisez la méthode GET pour envoyer des notifications",
        enter_the_return_url_here: "Entrez l'URL de retour ici",
        global_return_url: "Return URL Globale",
        enter_your_notification_url_here : "Entrez l'URL de notification ici",
        instant_notification_url: "URL de Notification Instantanée",
        get_request: "Requête GET",
        put_request: "Requête PUT",
        global: {
            the_return_url_is_being_called_text: "La Return URL est appelée par Smobilpay  for e-commerce après qu'un paiement a été effectué sur la page de paiement et que le système redirige l'utilisateur vers votre application.",
            you_can_define_a_global_url_text: "Vous pouvez définir une URL globale ici, qui sera toujours utilisée, sauf si vous fournissez une URL spécifique dans la demande initiale de placement de commande de paiement.",
            please_specify_how_you_would_text: "Veuillez préciser comment vous souhaitez que nous appelions votre système lors de la redirection de retour:",
            we_shall_direct_users_back_text: "Nous renverrons les utilisateurs vers votre magasin par le biais d'une requête GET. L'identifiant de référence ainsi que le statut du paiement seront ajoutés en tant que paramètres GET à l'URL de retour. Utilisez cette option lorsque votre système ne prend pas en charge les URL de retour individualisées au moment de la création de la commande et que vous avez besoin que nous vous fournissions des informations sur la commande dans le rappel.",
            enhanced_get_request: "Requête GET Renforcé",
            we_shall_direct_users_back_second_text: "Nous redirigerons les utilisateurs vers votre application en utilisant une requête GET. L'ID de référence et le statut de paiement seront inclus en tant que paramètres GET dans l'URL de retour globale spécifiée ci-dessus. Utilisez cela si votre système ne prend pas en charge actuellement les URL de retour personnalisées lors de la création de la commande, et si vous souhaitez que nous partagions les informations de commande via le callback",
            simple_get_request: "Requête GET simple",
            please_provide_a_return_url:'Veuillez fournir une URL de retour'
        },
        instant: {
            provide_a_url_for_immediate : "Fournir une URL pour les notifications immédiates sur les changements d'état de la commande",
            this_url_will_be_used : "Cette URL sera utilisée à moins qu'une URL différente ne soit fournie avec la demande d'ordre de paiement.",
            provide_a_webhook_here_text: "Fournissez un webhook ici pour recevoir des notifications immédiates à chaque fois qu'il y a un changement dans le statut du paiement de la commande.",
            you_can_define_a_global_text: "Vous pouvez définir une URL globale par défaut ici, qui sera utilisée de manière constante à moins que vous ne spécifiiez une URL différente dans la demande initiale de placement de commande.",
            please_specify_how_you_would_text: "Aviser Veuillez préciser la manière dont vous souhaitez qu’on avise votre système :",
            we_shall_send_a_put_request_text: "Nous enverrons une requête PUT à l'URL que vous avez fournie, où la référence est ajoutée 	en tant que variable de chemin dans l'URL et le statut du paiement dans le corps de la 	r	requête.",
            we_shall_send_a_get_request_text: "Nous enverrons une requête GET à l'URL que vous avez fournie, où la référence et le statut sont ajoutés en tant que variables de chemin dans l'URL.",
            please_provide_a_notification_url:'Veuillez fournir une URL de notification'
        }
    },
    editProfileMerchant: {
        title: "Mon profil",
        basicInfo: " 1. Information de base",
        companyInfo: "2. Profil de l’entreprise",
        contactInfo: "3. Coordonnées",
        changePassword: "4. Modifier le mot de passe",
        paymentInfo: "5. Détails de paiement",
        supportInfo: "6. Informations d’assistance",
        enable: "Activé",
        disable: "Désactivé",
        yes: "Oui",
        no: "Non",
    },
    changePasswordForm: {
        title: "Mon profil",
        changePassword: "Modifier votre mot de passe",
        currentPassword: "Votre mot de passe actuel",
        newPassword: "Votre nouveau mot de passe",
        confirmPassword: "Confirmation nouveau mot de passe",
        confirmPasswordError: "Les mots de passe ne correspondent pas",
        newPasswordError:
            "Le nouveau mot de passe doit être différent du mot de passe actuel",
        wrongCurrentPassword: "Le mot de passe actuel est incorrect",
    },
    menuApp: {
        editPassword: "Modifier le mot de passe",
        apiSettings: "Configuration API",
        logins: "CONNEXION",
    },
    sideBarTitles: {
        transactions: "Toutes transactions",
        merchants: "Marchands",
        commissions: "Commissions",
        payment: "Versements",
        payout: "Paiements",
        payouts: "Paiements",
        editProfile: "Modifier le profil",
    },
    resources: {
        register: {
            fields: {
                userLogin: "Utilisateur",
                companyName: "Entreprise",
                email: "Email",
                contactPersonFirstName: "Prénom",
                contactPersonLastName: "Nom",
                address: {
                    address: "Adresse",
                    state: "Région/Province",
                    city: "Ville",
                    postalCode: "Code postal",
                    countryIso3Code: "Code pays",
                },
                currency: "Dévise",
                phoneNumber: "Numéro de téléphone",
                faxNumber: "Numéro de fax",
                password: "Mot de passe",
                confirmPassword: "Confirmer le mot de passe",
                receiveNewsletter: "Recevoir des newsletters",
                yes: "Oui",
                no: "Non",
            },
            phoneError: "Le numéro de téléphone n'est pas valide",
            userLoginError: "Le nom d'utilisateur n'est pas valide",
            userLoginInUseError: "Le nom d'utilisateur déjà utilisé",
            emailInUseError: "L'adresse e-mail déjà utilisée",
            companyNameInUseError: "Le nom de l'entreprise déjà utilisé",
            title: "Création de compte utilisateur",
            basicInfo: " 1. Information de base",
            companyInfo: "2. Profil de l’entreprise",
            contactInfo: "3. Coordonnées",
            submitButton: "SOUMETTRE ET CONTINUER",
            passwordMatchError: "Les mots de passe ne correspondent pas",
            usernameError:
                "Votre identifiant ne peut contenir que des lettres minuscules et des chiffres",
        },
        transactions: {
            fields: {
                orderStatus: "Statut commande",
                date: "Date de création",
                processedDate: "Date de traitement",
                merchant: "Nom du marchand",
                merchantId: "ID Marchand",
                provider: "Opérateur",
                accountN: "Numéro du compte",
                accountName: "Nom du compte",
                orderN: "Commande#",
                amount: "Montant",
                status: "Statut",
                externalRefId: "ID Externe",
            },
            orderCard: {
                status: "Statut Commande",
                details: "Détails",
                orderExternal: "ID Commande Ext",
                amount: "Montant",
                ID: "ID",
                serviceCharge: "Frais de service",
                totalAmount: "Montant Total",
            },

            dateAfter: "Jour d’après",
            dateBefore: "Jour d’avant",
            orderExternal: "ID de la commande externe",
            amountAbove: "Montant supérieur à",
            merchantId: "Identifiant du marchand",
            status: "Statut",
            paymentProvider: "Opérateur",
            from: "De",
            to: "Jusqu’à",
        },
        status: {
            CONFIRMED: "Payé",
            FAILED: "Échoué",
            CANCELED: "Annulé",
            IN_PROGRESS: "En cours",
            CREATED: "Créé",
            INITIALISED: "Initié",
            EXPIRED: 'Expiré',
        },
        activated: {
            true: "OUI",
            false: "NON",
        },
        "merchant/account": {
            fields: {
                website: "Site web",
                companyName: "Entreprise",
                email: "Email",
                profile: "EDITER",
                deactivation: "DESACTIVé",
                activation: "activé",
                contactPersonFirstName: "Prénom",
                contactPersonLastName: "Nom",
                address: {
                    address: "Adresse",
                    state: "Région/Province",
                    city: "Ville",
                    postalCode: "Code postal",
                    countryIso3Code: "Code pays",
                },
                currency: "Dévise",
                phoneNumber: "Téléphone",
                langKey: "Language",
                faxNumber: "Numéro de fax",
                supportEmail: "Email d’assistance",
                password: "Mot de passe",
                confirmPassword: "Confirmation du mot de passe",
                receiveNewsletter: "Recevoir des newsletters",
                payoutDetails: {
                    type: "Type",
                    acquirer: "Opérateur",
                    accountNumber: "Numéro du compte",
                    accountName: "Nom du compte",
                    customerReference: "Référent client",
                },
            },
        },
        merchants: {
            fields: {
                login: "Utilisateur",
                activated: "Activation",
                website: "Site web",
                companyName: "Entreprise",
                countryIso3Code: "Code pays",
                email: "Email",
                profile: "MODIFIER",
                deactive: "desactiver",
                active: "activer",
                deactivation: "désactivé",
                activation: "activé",
                merchant: "le marchand",
                title: "Mettre à jour le compte",
                confirmation: "Êtes-vous sûr de vouloir",
                contactPersonFirstName: "Prénom",
                contactPersonLastName: "Nom",
                address: "Adresse",
                state: "Région/Province",
                city: "Ville",
                postalCode: "Code postal",
                currency: "Dévise",
                phoneNumber: "Téléphone",
                faxNumber: "Numéro de fax",
                supportEmail: "Email d’assistance",
                payoutType: "Type",
                payoutAcquirer: "Opérateur",
                payoutAccountNumber: "Numéro du compte",
                payoutAccountName: "Nom du compte",
                payoutCustomerReference: "Référent client",
                countryCode: "Code pays",
            },

            forms: {
                editMerchant: "Éditer le marchand",
                viewDetails: `Voir Détails`,
                editPayment: `Editer Versements`,
                editPayout: `Editer Paiements`,
                additionalFees: `Frais supplémentaires`,
                themes: "Thèmes",
                editPayoutMethod: "Modifier la méthode de paiement",
                basicInfo: " 1. Information de base",
                companyInfo: "2. Profil de l’entreprise",
                contactInfo: "3. Coordonnées",
                paymentInfo: "4. Détails de paiement",
                payments: {
                    provider: "Opérateur",
                    active: "Actif",
                    commissionPreset: "Commissions Pédéfinies",
                    enable: "Activé",
                    disable: "Désactivé",
                },
                payouts: {
                    acquier: "Opérateur",
                    commissionPreset: "Commissions Prédéfinies",
                    providerName: "Nom de l'opérateur",
                    friendlyName: "Nom convivial",
                    currency: "Devise",
                    countries: "Les pays",
                    active: "Actif",
                    applicableFees: "Taxes applicables",
                    uploadLogo: "Télécharger le logo",
                    uploadFormatText: "Taille recommentée à 188x40 px pour de meilleurs résultats",
                    clickToSelectFile: "Cliquez pour sélectionner un fichier",
                    supportedFilesText: "extensions supportées : jpeg .png",
                    previewImage: "Aperçu de l'image",
                    configureTheme: "Configurer le thème",
                    configureThemeText: "Modifier la couleur de certains composants de l'interface utilisateur sur la page (carte, tableau, bouton)",
                    headerBackgroundColor: "Couleur d'arrière-plan de l'en-tête",
                    primaryColor: "Couleur primaire",
                    secondaryColor: "Couleur secondaire",
                    pickColor: "Choisissez une couleur",
                },
                fees:{
                    enableLineItem: "Activer des éléments supplementaires",
                    enableItemDescription: "Activez cette fonction pour inclure des elements supplémentaires, tels que les taxes et les frais, dans le calcul du panier total.",
                }
            },
        },
        payments: {
            fields: {
                name: "Nom",
                currency: "Dévise",
                description: "Description",
                fees: "Frais",
                min: "Min",
                max: "Max",
                fixed: "Fixé",
                percentage: "Pourcentage",
            },
            grid: {
                min: "Min",
                max: "Max",
                fixed: "Fixé",
                percentage: "Pourcentage",
            },
            editTitle: "Editer Préselection",
            createTitle: "Créer un Préselection",
            addNewPreset: "AJOUT NOUVELLE PRÉSELECTION",
            route: "Commissions/Versements",
        },
        payout: {
            fields: {
                name: "Nom",
            },
            route: "Commissions/Paiements",
        },
        payouts: {
            currentBalance: "Solde actuel: ",
            requestPayout: "DEMANDE PAIEMENT",
            dialog: {
                trigger: {
                    title: "Approuver le paiement",
                    content: "Voulez-vous vraiment approuver ce paiement?",
                },
                decline: {
                    title: "Décliner le paiement",
                    content: "Voulez-vous vraiment décliner ce paiement ?",
                },
            },
            fields: {
                name: "Name",
                externalReference: "Référence externe",
                date: "Date",
                merchantName: "Marchand",
                totalAmount: "Montant",
                fee: "Frais",
                comment: "Commentaire",
                payoutStatus: "Statut paiement",
                title: "Paiements",
            },
            forms: {
                title: "Informations sur la demande de paiement",
                charge: "Frais de facturation",
                amount: "1. Montant",
                totalAmount: "Montant total",
                comment: "2. Commentaire",
                triggerButton: "DEMANDE PAIEMENT",
                submitButton: "DEMANDE PAIEMENT",
                cancelButton: "ANNULER",
                declineButton: "REFUSER",
                titleCreate: "Ma demande de paiement",
                grid: {
                    title: "Détails de paiement",
                    payoutType: "Type de paiement",
                    payoutAcquirer: "Opérateur",
                    payoutAccountNumber: "Numéro de compte",
                    payoutAccountName: "Nom du compte",
                },
                cardEdit: {
                    status: "Statut de paiement",
                    details: "Détails",
                    externalReference: "ID Référence externe",
                    payoutDate: "Date Paiement",
                    currency: "Dévise",
                    merchant: "Nom du Marchand",
                    customerReference: "Référence client",
                },
                cardCreate: {
                    currentBalance: "Solde actuel",
                    details: "Détails",
                    merchantId: "ID Marchand",
                    phoneNumber: "Téléphone",
                    address: "Adresse",
                    region: "Province/Ville/Pays",
                    email: "Email",
                },
            },
            payoutStatus: {
                PENDING: "En attente",
                SUCCESSFUL: "Échoué",
                FAILED: "Réussie",
            },
        },
    },
};

export default customFrenchMessages;
