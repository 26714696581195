import {makeStyles} from "@material-ui/core/styles";
import palette from "../../../styles/palette";

export const useStyles = makeStyles(theme => ({
    root: {
        width: '100% !important',
    },
    paper: {
        boxShadow: 'none',
        border: '1px solid #0AA5B7',
        marginTop: theme.spacing(9),
        paddingTop: 0
    },
    form: {
        marginLeft: theme.spacing(9),
        marginTop: theme.spacing(6),
        border: " 1px solid #0AA5B7",
        borderRadius: "5px",
        "& .MuiCardContent-root": {
            padding: theme.spacing(8),
        },
    },
    fieldWrapper: {
        display: 'flex',
        width: '50%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingRight: theme.spacing(2),
    },
    inputMedium: {
        width: "100%",
        marginRight: theme.spacing(2),
        "& .MuiFilledInput-input": {
            backgroundColor: theme.palette.primary.main,
            borderRadius: "15px 15px 0px 0px"
        },
        "& .MuiFilledInput-root": {
            marginRight: theme.spacing(2),
            backgroundColor: theme.palette.primary.main,
            borderRadius: "15px 15px 0px 0px"
        },
        "& .MuiInputBase-input": {
            backgroundColor: theme.palette.primary.main,
            borderRadius: "15px 15px 0px 0px"
        },
        "& .MuiInputBase-root": {
            backgroundColor: theme.palette.primary.main,
            borderRadius: "15px 15px 0px 0px"
        },
    },
    inputSmall: {
        width: "45%",
        "& .MuiFilledInput-input": {
            backgroundColor: theme.palette.primary.light,
            borderRadius: "15px 15px 0px 0px"
        },
        "& .MuiFilledInput-root": {
            marginRight: theme.spacing(2),
            borderRadius: "15px 15px 0px 0px",
            backgroundColor: theme.palette.primary.light,
            width: "100%",
        },
        "& .MuiInputBase-input": {
            width: "100%",
        },
    },
    postcode: {

        width: "45%",
        "& .MuiFilledInput-input": {
            backgroundColor: theme.palette.primary.light,
            borderRadius: "15px 15px 0px 0px",
        },
        "& .MuiFilledInput-root": {
            marginRight: theme.spacing(2),
            width: "100%",
            borderRadius: "15px 15px 0px 0px",
        },
        "& .MuiInputBase-input": {
            width: "100%",
        },
    },
    password: {
        marginRight: theme.spacing(2),
        width: "100%",
        "&:hover:active": {
            backgroundColor: theme.palette.primary.light,
        },
        "& .MuiFilledInput-input": {
            backgroundColor: theme.palette.primary.light,
            borderRadius: "15px 15px 0px 0px",
            "&:hover:active": {
                backgroundColor: theme.palette.primary.light,
            },
        },
        "& .MuiFilledInput-root": {
            marginRight: theme.spacing(2),
            borderRadius: "15px 15px 0px 0px",
            backgroundColor: theme.palette.primary.light,
            "&:hover:active": {
                backgroundColor: theme.palette.primary.light,
            },
        },
    },
    titleForm: {
        width: "100%",
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        borderBottom: '1px solid #0AA5B7',
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        fontWeight: 700,
        color: palette.primary.main,
        fontSize: '1.5rem',
    },

    title: {
        fontSize: '1.125rem',
        fontWeight: 700,
        color: palette.primary.main,
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'row',
    },
    radio: {
        "& .MuiSvgIcon-root": {
            fontSize: "0.75rem",
        },
    },
    label: {
        "& .MuiTypography-body1": {
            fontSize: 14,
        },
    },
}));