import React from "react";
import {FunctionField, translate,useTranslate} from "react-admin";
import get from 'lodash/get';


const TranslatedField = (({ namespace, ...props}) => {
    const translate= useTranslate()
    return (
        <FunctionField {...props}
                       render={(record, source) => translate(`${namespace}.${get(record, source)}`)}
        />
    )
})
export default TranslatedField;