

export default [
    {
        id: 'acquirer',
        label:'resources.merchants.forms.payouts.acquier',
        key: 'acquirer',
        type: 'string',
        align: "left",
    },
    {
        id: 'commission',
        label:'resources.merchants.forms.payouts.commissionPreset',
        key: 'commission',
        type: 'string',
        align: "left",
    },
];
