import React, {useEffect} from 'react';

import "./App.css"
import {ThemeProvider} from "@material-ui/core/styles";
import {CssBaseline} from '@material-ui/core';
import {theme} from "./styles/theme";

import StyledLayout from "./layout/Layout";
import {Admin, Resource} from 'react-admin';

import {authProvider} from "./authProvider";
import dataProvider from "./customDataProvider"

import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import frenchMessages from 'ra-language-french';
import {domainMessages} from "./i18n";
import customRoute from "./routes";

import orders from "./views/orders";
import merchants from "./views/merchants";
import payout from "./views/comissions/payout";
import {PayoutsFormCreate, PayoutsFormEdit} from "./views/payouts/EditPayoutForm";
import {PayoutsList} from "./views/payouts/PayoutsList";
import {CreatePresetForm, EditPresetForm} from "./views/comissions/PresetForm/PresetForm";
import {PaymentList} from "./views/comissions/payments/PaymentList";
import {LoginPage} from "./views/LoginPage";
import IdleTimer from "./utils/IdleTimer";


const messages = {
    fr: {...frenchMessages, ...domainMessages.fr},
    en: {...englishMessages, ...domainMessages.en},
};
const langKey = localStorage.getItem('langKey')
const i18nProvider = polyglotI18nProvider(locale => messages[locale], langKey || 'en');

const App = () => {

    useEffect(() => {
        const timer = new IdleTimer({
            timeout: 900,
            onTimeout: () => {
                window.location.replace('#/login')
                localStorage.setItem('isAuth', false);
                localStorage.removeItem('_expiredTime');
            },
            onExpired: () => {
                // do something if expired on load
                window.location.replace('#/login')
                localStorage.setItem('isAuth', false);
                localStorage.removeItem('_expiredTime');
            }
        });


        return () => {
            timer.cleanUp();
        };
    }, []);


    return (
        <>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <Admin
                    locale={langKey || 'en'}
                    i18nProvider={i18nProvider}
                    disableTelemetry={true}
                    title={'E-nkap'}
                    loginPage={LoginPage}
                    authProvider={authProvider}
                    theme={theme}
                    dataProvider={dataProvider}
                    layout={StyledLayout}
                    customRoutes={customRoute}
                >
                    {permissions => [
                        permissions === 'ROLE_BUSINESS_ADMIN' || permissions === 'ROLE_MERCHANT'
                            ?
                            <Resource name="order/report"  {...orders} /> : null,
                        <Resource name="payouts"
                                  list={PayoutsList}
                                  edit={permissions === 'ROLE_BUSINESS_ADMIN' ? PayoutsFormEdit : null}
                                  create={permissions === 'ROLE_MERCHANT' ? PayoutsFormCreate : null}
                        />,
                        permissions === 'ROLE_BUSINESS_ADMIN'
                            ?
                            <Resource name="merchants" {...merchants}/> : null,
                        permissions === 'ROLE_MERCHANT'
                            ?
                            <Resource name="merchant/account"/> : null,
                        permissions === 'ROLE_BUSINESS_ADMIN'
                            ?
                            <Resource name="payments" list={PaymentList} create={CreatePresetForm}
                                      edit={EditPresetForm}/> : null,
                        permissions === 'ROLE_BUSINESS_ADMIN'
                            ?
                            <Resource name="payout" {...payout}  /> : null,
                        <Resource name="balance"/>,
                        <Resource name="pricings"/>,
                    ]}
                </Admin
                >
            </ThemeProvider>
        </>
    )
}
export default App;
