import {createTheme} from '@material-ui/core/styles';
import typography from './typography';
import palette from './palette';


export const theme = createTheme({
    palette,
    typography,
    overrides: {
        MuiFilledInput: {
            root: {
                backgroundColor: '#E7FCFF',
            },
        },
        MuiButton: {
            containedPrimary: {
                color: 'white',
                borderRadius: "20px",
            },
        },
    },
});




