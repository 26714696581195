

export default {
    red: '#ff0000',
    orange: '#f39410',
    green: '#6daf57',
    darkBlue: '#303668',
    lightBlue: '#0d92dd',

    text: {
        primary: '#000000',
        secondary: '#0AA5B7',
    },
    border: {
        default: '#d3ccd2',
    },
    background: {
        default: '#ffffff',
    },
    primary: {
        main: '#0AA5B7',
        light: '#E7FCFF',
    },
    secondary: {
        main: '#0AA5B7',
        dark: '#6daf57',
        contrastText: '#ffffff',
    },
    grey: {
        350: '#c4c4c4',
        750: '#545c64',
        100: '#f6f6f6',
        med: '#dcdddd'
    },
};
