import React from 'react';
import Typography from '@material-ui/core/Typography';
import {useTranslate} from "react-admin";


export const SectionTitle = ({label, className}) => {
const translate = useTranslate()

    return (
        <Typography className={className} gutterBottom>
            {translate(label)}
        </Typography>
    );
};