import * as React from 'react';
import {useEffect, useState} from 'react';
import {SaveButton, useNotify, useTranslate} from 'react-admin';
import {
    CircularProgress,
    Grid,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import columns from "./helpers/OrderColumns";
import axios from "axios";

export const useStyles = makeStyles(theme => ({
        form: {
            marginLeft: theme.spacing(9),
            marginTop: theme.spacing(6),
            border: " 1px solid #0AA5B7",
            borderRadius: "5px",
            "& .MuiCardContent-root": {
                padding: theme.spacing(8),
            },
        },
        root: {
            fontSize: '1.25rem',
            width: 'fit-content',
            border: '1px solid #0AA5B7',
            color: 'rgba(0, 0, 0, 0.5)'
        },
        toolBar: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            height: 65,
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            backgroundColor: theme.palette.primary.light,
            marginTop: theme.spacing(8),
        },
        tableHeader: {
            color: theme.palette.text.secondary,
            paddingLeft: theme.spacing(5),
            paddingRight: theme.spacing(5),
            fontSize: '1.6rem',
            fontWeight: 'bold',
            border: " 1px solid #0AA5B7",
            backgroundColor: '#C4E4E8',
            textTransform: 'capitalize',
            verticalAlign: 'top',
        },
        inputMedium: {
            maxWidth: 400,
            width: "100%",
            backgroundColor: theme.palette.primary.light,
            marginRight: theme.spacing(2),
            color: "rgba(0, 0, 0, 0.45)",
            textAlign: "left",
            padding: theme.spacing(1.5),
            fontSize: '1.25rem',

        },

    }
));


const EditPayoutForm = (props) => {
    const classes = useStyles();
    const [payoutPricings, setPayoutPricings] = useState([{
        id: null,
        pricingId: null,
        name: "Empty preset",
    }]);
    const notify = useNotify();
    const translate = useTranslate();
    const [merchantPayout, setMerchantPayout] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const data = []


    const handleSubmit = (data) => {
        const request = ({
            method: 'POST',
            withCredentials: true,
            headers: ({'Content-Type': 'application/json'}),
            credential: 'include',
            data: data
        });
        axios(`${window._env_.REACT_APP_API_HOST}` + `/merchant/api/merchants/${props.record.merchantId}/payout-configurations`, request)
            .then(res => {
                res.status === 200 ? notify("Data successfully updated") : notify('Error');
                setMerchantPayout(res.data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                console.log(error);
                setLoading(false);
            })
    }
    useEffect(() => {
        const request = ({
            method: 'GET',
            withCredentials: true,
            headers: ({'Content-Type': 'application/json'}),
        });
        axios(`${window._env_.REACT_APP_API_HOST}` + `/merchant/api/merchants/${props.record.merchantId}/payout-configurations`, request)
            .then(({data}) => {
                setMerchantPayout(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                console.log(error);
                setLoading(false);
            })
        axios(`${window._env_.REACT_APP_API_HOST}` + '/merchant/api/pricings?filter=type:MERCHANT', request)
            .then(({data}) => {
                setPayoutPricings([...payoutPricings, ...data]);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                console.log(error);
                setLoading(false);
            })

    }, []);
    return ((
            (!loading && merchantPayout && payoutPricings.length > 1) ? <>

                <Grid container direction='row' justifyContent='center'>
                    <Table className={classes.root}>
                        <TableHead>
                            <TableRow className={classes.tableHeader}>
                                {columns.map((column) => (
                                    <TableCell
                                        classes={{root: classes.tableHeader}}
                                        key={column.id}
                                        align={column.align}
                                    >
                                        {translate(column.label)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {merchantPayout?.map(row => (<TableRow
                                key={row?.merchantId?.uuid}
                            >
                                <TableCell
                                    classes={{
                                        root: classes.root,
                                    }}
                                    align="center" component="th" scope="row">
                                    {row.type}
                                </TableCell>
                                <TableCell
                                    classes={{
                                        root: classes.root,
                                    }} align="center">
                                    <Select
                                        id={'id'}
                                        label={false}
                                        className={classes.inputMedium}
                                        defaultValue={
                                            payoutPricings?.find(pricing => pricing?.pricingId === row?.specialPriceId?.uuid) ?
                                                payoutPricings?.find(pricing => pricing?.pricingId === row?.specialPriceId?.uuid) :
                                                payoutPricings?.find(pricing => pricing?.pricingId === null)}
                                        onChange={(e) => {
                                            data.push({...row, specialPriceId: {uuid: e.target.value.pricingId}})
                                        }}
                                    >
                                        {payoutPricings?.map(i => <MenuItem id={i.id} value={i}>{i.name}</MenuItem>)}
                                    </Select>
                                </TableCell>
                            </TableRow>))}
                        </TableBody>
                    </Table>
                </Grid>
                <Grid className={classes.toolBar}>
                    <SaveButton
                        handleSubmitWithRedirect={() => handleSubmit(data)}
                    >Submit</SaveButton>
                </Grid>
            </> : <CircularProgress/>
        )
    )
};


export default EditPayoutForm;