import { makeStyles } from "@material-ui/core/styles";
import palette from "../../../styles/palette";
export const useStyles = makeStyles((theme) => ({
  toolBar: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: 65,
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(3),
    backgroundColor: theme.palette.primary.light,
    marginTop: theme.spacing(8),
  },
  title: {
    color: palette.primary.main,
    fontFamily: "IBM Plex Sans",
    fontSize: "1.25rem",
    fontWeight: 700,
    marginBottom: "30px !important",
  },
  text: {
    marginBottom: "30px !important",
  },
  inputMedium: {
    width: "100%",
    borderBottom: "0 solid",
    "& .MuiFilledInput-input": {
      backgroundColor: theme.palette.primary.light,
    },
    "& .MuiInputBase-input": {
      backgroundColor: theme.palette.primary.light,
      borderRadius: "15px 15px 0px 0px",
    },
  },
  fileInputWrapper: {
    width: "70%",
    height: "100%",
    marginRight: 10,
  },
  inputFileMedium: {
    width: "100%",
    color: "#fff",
    border: "1px dashed",
    display: "none",
    "& .MuiFilledInput-input": {
      backgroundColor: theme.palette.primary.light,
    },
    "& .MuiInputBase-input": {
      backgroundColor: theme.palette.primary.light,
      borderRadius: "15px 15px 0px 0px",
    },
  },
  fileInputLabel: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px dashed",
    padding: "20px 10px",
    borderRadius: 5,
    width: "100%",
    minHeight: "120px",
    color: "gray",
  },
  fileInputLabelProps: {
    backgroundColor: "red",
    color: "green",
    border: "2px dashed",
    width: "100%",
    height: "100%",
  },
  imagePreview: {
    position: "relative",
    width: "30%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px dashed gray",
    padding: "20px 10px",
    borderRadius: 5,
    maxHeight: "120px",
    maxWidth: "30%",
    color: "gray",
  },
  cancelIcon: { position: "absolute", top: 5, right: 5 },
}));
