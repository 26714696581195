import * as React from 'react';
import {useEffect, useState} from 'react';
import {
    Edit,
    email,
    PasswordInput,
    RadioButtonGroupInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput,
    useDataProvider,
    useTranslate
} from 'react-admin';
import {Box, Grid} from "@material-ui/core";
import {SectionTitle} from "../../../components/SectionTitle";
import {useStyles} from "./styles";
import {allCountries, langKey, paymentType, supportedCurrencies} from "../../../helpers/choices";
import {CustomToolbar} from "../../../components/CustomToolbar";


const MerchantForm = (props) => {
    const classes = useStyles();
    const translate = useTranslate()
    const language = localStorage.getItem('langKey')
    const dataProvider = useDataProvider();
    const [id, setId] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    useEffect(() => {
        dataProvider.getOne('merchant/account', {})
            .then(({data}) => {
                setId(data.id);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                console.log(error);
                setLoading(false);
            })

    }, []);

    return (
        <Edit
            classes={{
                card: classes.paper,
            }}
            id={id}
            redirect={false}
            resource='merchant/account'
            title={"editProfileMerchant.title"}
            {...props}
            basePath="/my-profile"
            {...props} >
            <SimpleForm
                toolbar={<CustomToolbar handleSubmitWithRedirect={'list'}/>}
            >
                <Grid className={classes.titleForm}>
                    {translate("editProfileMerchant.title")}
                </Grid>
                <Grid container className={classes.root} direction='row' justifyContent='space-between'>
                    <Box width={"70%"}>
                        <SectionTitle
                            className={classes.title}
                            label="editProfileMerchant.basicInfo"/>
                        <Box className={classes.wrapper}>
                            <TextInput
                                resource={'merchant/account'}
                                source="contactPersonFirstName"
                                className={classes.inputMedium}
                                validate={requiredValidate}
                            />
                            <TextInput
                                resource={'merchant/account'}
                                source="contactPersonLastName"
                                className={classes.inputMedium}
                                validate={requiredValidate}
                            />
                        </Box>

                        <SectionTitle
                            className={classes.title}
                            label="editProfileMerchant.companyInfo"/>
                        <Box className={classes.wrapper}>
                            <TextInput
                                resource={'merchant/account'}
                                source="companyName"
                                className={classes.inputMedium}
                                validate={requiredValidate}
                            />
                            <TextInput
                                resource={'merchant/account'}
                                source="address.address"
                                className={classes.inputMedium}
                                validate={requiredValidate}
                            />
                        </Box>
                        <Box className={classes.wrapper}>
                            <Box width={'50%'} mr={2}>
                                <TextInput
                                    resource={'merchant/account'}
                                    source="address.state"
                                    className={classes.inputMedium}
                                    validate={requiredValidate}
                                />
                            </Box>
                            <Box className={classes.fieldWrapper}>
                                <TextInput
                                    resource={'merchant/account'}
                                    source="address.city"
                                    margin={'dense'}
                                    className={classes.inputSmall}
                                    validate={requiredValidate}
                                />
                                <TextInput
                                    resource={'merchant/account'}
                                    source="address.postalCode"
                                    margin={'dense'}
                                    className={classes.postcode}
                                    validate={requiredValidate}
                                />
                            </Box>
                        </Box>
                        <Box className={classes.wrapper}>
                            <SelectInput
                                resource={'merchant/account'}
                                source="address.countryIso3Code"
                                className={classes.inputMedium}
                                validate={requiredValidate}
                                choices={allCountries}
                                optionText="name"
                                optionValue="code"
                            />
                            <SelectInput
                                resource={'merchant/account'}
                                source="currency"
                                className={classes.inputMedium}
                                validate={requiredValidate}
                                choices={supportedCurrencies}
                                optionText="fullDescription"
                                optionValue="code"
                            />
                        </Box>
                        <SectionTitle
                            className={classes.title}
                            label="editProfileMerchant.contactInfo"/>
                        <Box className={classes.wrapper}>
                            <TextInput
                                resource={'merchant/account'}
                                source="telephone"
                                className={classes.inputMedium}
                                validate={requiredValidate}
                            />
                            <TextInput
                                resource={'merchant/account'}
                                source="faxNumber"
                                className={classes.inputMedium}
                            />
                        </Box>
                        <Box className={classes.wrapper}>
                            <TextInput
                                resource={'merchant/account'}
                                type="email"
                                source="email"
                                validation={{email: true}}
                                className={classes.inputMedium}
                                validate={[required(), email()]}
                            />
                            <SelectInput
                                resource={'merchant/account'}
                                source="langKey"
                                className={classes.inputMedium}
                                defaultValue={language}
                                optionText="value"
                                optionValue="id"
                                choices={langKey}
                            />
                        </Box>
                        <SectionTitle
                            className={classes.title}
                            label="editProfileMerchant.changePassword"/>
                        <Box className={classes.wrapper}>
                            <PasswordInput
                                resource={'merchant/account'}
                                source="password"
                                className={classes.password}
                            />
                            <PasswordInput
                                resource={'merchant/account'}
                                source="confirmPassword"
                                className={classes.password}
                            />
                        </Box>
                    </Box>
                    <Grid item justifyContent={'space-between'}>
                        <SectionTitle
                            className={classes.title}
                            label="editProfileMerchant.paymentInfo"/>

                        <SelectInput
                            resource={'merchant/account'}
                            source="payoutDetails.type"
                            className={classes.inputMedium}
                            choices={paymentType}
                            optionText="value"
                            optionValue="value"
                        />
                        <Separator/>
                        <TextInput
                            resource={'merchant/account'}
                            source="payoutDetails.acquirer"
                            className={classes.inputMedium}

                        />
                        <Separator/>
                        <TextInput
                            resource={'merchant/account'}
                            source="payoutDetails.accountNumber"
                            className={classes.inputMedium}
                        />
                        <Separator/>
                        <TextInput
                            resource={'merchant/account'}
                            source="payoutDetails.accountName"
                            className={classes.inputMedium}
                        />
                        <Separator/>
                        <TextInput
                            resource={'merchant/account'}
                            source="payoutDetails.customerReference"
                            className={classes.inputMedium}
                        />
                        <Separator/>
                        <SectionTitle
                            className={classes.title}
                            label="editProfileMerchant.supportInfo"/>
                        <Separator/>
                        <Box
                            className={classes.wrapper}>
                            <TextInput
                                resource={'merchant/account'}
                                type="email"
                                source="supportEmail"
                                validation={{email: true}}
                                className={classes.inputMedium}
                            />
                            <RadioButtonGroupInput
                                label={false}
                                source="supportEmailEnabled"
                                choices={[
                                    {id: true, name: `${translate('editProfileMerchant.enable')}`},
                                    {id: false, name: `${translate('editProfileMerchant.disable')}`}
                                ]}
                                optionText="name"
                                optionValue="id"
                            />
                        </Box>
                        <RadioButtonGroupInput
                            resource={'merchant/account'}
                            source="receiveNewsletter"
                            choices={[
                                {checked: true, name: `${translate('editProfileMerchant.yes')}`},
                                {checked: false, name: `${translate('editProfileMerchant.no')}`},
                            ]}
                            optionText="name"
                            optionValue="checked"
                        />
                    </Grid>

                </Grid>
            </SimpleForm>
        </Edit>

    )
};
const requiredValidate = [required()];
const Separator = () => <Box pt="1em"/>;
export default MerchantForm;