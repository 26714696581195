import * as React from "react";
import {
    CreateButton,
    Datagrid,
    DeleteButton,
    EditButton,
    List,
    Pagination,
    SearchInput,
    TextField,
    TopToolbar,
    useResourceContext
} from 'react-admin';
import {ThemeProvider} from '@material-ui/core/styles';
import {themeMUI} from "./styles";
import PresetGrid from "../PresetGrid";
import palette from "../../../styles/palette";

const ordersFilters = [
    <SearchInput source="name" alwaysOn/>,
];
const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;
const ResourceName = () => {
    const {resource} = useResourceContext();
    return <>{resource}</>;
}

const AddNewPresetButton = () => (
    <CreateButton basePath="/payout" label="resources.payments.addNewPreset"/>
);
const ListActions = (props) => {

    return (
        <TopToolbar>
            <AddNewPresetButton/>
        </TopToolbar>)
};
export const PayoutList = props => {

    return (
        <ThemeProvider theme={themeMUI()}>
            <List {...props}
                title={"resources.payout.route"}
                  pagination={<PostPagination/>}
                  filters={ordersFilters}
                  exporter={false}
                  actions={<ListActions {...props}/>}
            >
                <>
                    <ResourceName/>
                    <Datagrid
                        rowClick="expand"
                        expand={<PresetGrid/>}
                    >

                        <TextField source="name"/>
                        <EditButton
                            style={{
                                border: 'none',
                                backgroundColor: 'transparent',
                            }}
                            basePath="/payout"
                            record={"id"}
                        />
                        <DeleteButton
                            style={{
                                border: 'none',
                                backgroundColor: 'transparent',
                                color: palette.primary.main
                            }}/>
                    </Datagrid>
                </>
            </List>
        </ThemeProvider>

    )
};