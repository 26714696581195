import React from 'react';

const NumberFieldWithFormat = ({record = {}, source}) => {

    return (
        <span>{(record[source])?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span>
    );
}
export default NumberFieldWithFormat;

