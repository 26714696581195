import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {SaveButton, Toolbar} from "react-admin";


const useStyles = makeStyles(theme => ({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: `${theme.palette.primary.light} !important`,
        borderRadius: " 0px 0px 5px 5px",
    },

}));

export const CustomToolbar =  props => {
    const classes = useStyles();
    return (
        <Toolbar {...props} className={classes.toolbar}>
            <SaveButton
                label={props.label}
                redirect={'list'}
                submitOnEnter={true}
                disabled={props.invalid}
            />
        </Toolbar>)
};