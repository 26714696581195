import PeopleIcon from '@material-ui/icons/People';
import {MerchantsList} from './MerchantsList';
import {EditUserFormByAdmin} from "./EditUserFormByAdmin";


export default {
    list: MerchantsList,
    icon: PeopleIcon,
    edit: EditUserFormByAdmin
};
