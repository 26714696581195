import { fetchUtils } from "react-admin";
import { stringify } from "query-string";

const apiUrl = `${window._env_.REACT_APP_API_HOST}` + "/merchant/api";

const httpClient = fetchUtils.fetchJson;

export default {
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        let maxPage = perPage > 500 ? 500 : perPage;

        const d = new Date();
        const date =
            d.getFullYear() +
            `${d.getMonth() + 1 > 9 ? "-" : "-0"}` +
            (d.getMonth() + 1) +
            `${d.getDate() > 9 ? "-" : "-0"}` +
            d.getDate() +
            `T00:00:00.000Z`;
        const d2 = new Date();
        d2.setMonth(d2.getMonth() - 1);
        const monthBefore =
            d2.getFullYear() +
            `${d2.getMonth() + 1 > 9 ? "-" : "-0"}` +
            (d2.getMonth() + 1) +
            `${d2.getDate() > 9 ? "-" : "-0"}` +
            d2.getDate() +
            `T00:00:00.000Z`;
        d.setMonth(d.getMonth() - 1);

        const query = {
            sort: {
                field: field,
                order: order,
            },
            filter: {
                ...params.filter,
                [params.target]: params.id,
            },
        };

        let dateAfterFilter =
            query?.filter?.date_lte && query?.filter?.date_lte.split("T");
        let dateBeforeFilter =
            query?.filter?.date_lte && query?.filter?.date_gte.split("T");
        let dateAfter = query.filter?.date_gte
            ? dateBeforeFilter[0] + `T00:00:00.000Z`
            : query.filter?.date_lte
            ? null
            : monthBefore;
        let dateBefore = query.filter?.date_lte
            ? dateAfterFilter[0] + `T23:59:59.000Z`
            : query.filter?.date_gte
            ? null
            : date;
        let status = query.filter?.status
            ? `,{"attribute":"status","values":{"stringValue":["${query.filter.status}"]}}`
            : "";
        let payoutStatus = query.filter?.payoutStatus
            ? `,{"attribute":"payoutStatus","values":{"stringValue":["${query.filter.payoutStatus}"]}}`
            : "";
        let merchantName = query.filter?.merchantName
            ? `,{"attribute":"merchantName","values":{"stringValue":["${query.filter.merchantName}"]}}`
            : "";
        let merchantLogin = query.filter?.login
            ? `{"attribute":"login","values":{"stringValue":["${query.filter.login}"]}}`
            : "";
        let orderExternalRefId = query.filter?.orderExternalRefId
            ? `,{"attribute":"orderExternalRefId","values":{"stringValue":["${query.filter.orderExternalRefId}"]}}`
            : "";
        let amount = query.filter?.totalAmount
            ? `,{"attribute":"totalAmount","type":"GREATER_EQUAL","values":{"doubleValue":["${query.filter.totalAmount}",null]}}`
            : "";
        let sortDir = query.sort?.order === "ASC" ? true : false;
        let sortType = query.sort?.field ? query.sort?.field : "date";
        let commissionName = query.filter.name
            ? `,{"attribute":"name","values":{"stringValue":["${query.filter.name}"]}}`
            : "";
        // let type = dateAfter && dateBefore ? "BETWEEN" :(dateAfter && !dateBefore ? "GREATER_EQUAL" :  "LESS_EQUAL" )
        let customerPaymentAccountName = query.filter
            ?.customerPaymentAccountName
            ? `,{"attribute":"customerPaymentAccountName","values":{"stringValue":["${query.filter.customerPaymentAccountName}"]}}`
            : "";
        let paymentProviderName = query.filter?.paymentProviderName
            ? `,{"attribute":"paymentProviderName","values":{"stringValue":["${query.filter.paymentProviderName}"]}}`
            : "";
        let customerPaymentAccountNumber = query.filter
            ?.customerPaymentAccountNumber
            ? `,{"attribute":"customerPaymentAccountNumber","values":{"stringValue":["${query.filter.customerPaymentAccountNumber}"]}}`
            : "";
        let merchantLId = query.filter?.merchantLId
            ? `,{"attribute":"merchantLId","values":{"stringValue":["${query.filter.merchantLId}"]}}`
            : "";
        let url = "";
        switch (resource) {
            case "order/report":
            case "payouts":
                url = `${apiUrl}/${resource}/search/paging?pageNr=${page}&pageSize=${maxPage}&queryCriteria={"order":[{"attribute":"${sortType}","ascending":${sortDir}}],"filter":[{"attribute":"date","type":"BETWEEN","values":{"dateTimeValue":["${dateAfter}","${dateBefore}"]}}${status}${amount}${merchantName}${orderExternalRefId}${payoutStatus}${customerPaymentAccountName}${paymentProviderName}${customerPaymentAccountNumber}${merchantLId}]}`;
                break;
            case "merchants":
                url = `${apiUrl}/${resource}/search/paging?pageNr=${page}&pageSize=${maxPage}&queryCriteria={"order":[{"attribute":"${sortType}","ascending":${sortDir}}],"filter":[${merchantLogin}]}`;
                break;
            case "payout":
                url = `${apiUrl}/pricings/search/paging?pageNr=${page}&pageSize=${maxPage}&queryCriteria={"order":[{"attribute":"${sortType}","ascending":${sortDir}}],"filter":[{"attribute":"type","values":{"stringValue":["MERCHANT"]}}${commissionName}]}`;
                break;
            case "payments":
                // url = `${apiUrl}/pricings?filter=type:CUSTOMER`;
                url = `${apiUrl}/pricings/search/paging?pageNr=${page}&pageSize=${maxPage}&queryCriteria={"order":[{"attribute":"${sortType}","ascending":${sortDir}}],"filter":[{"attribute":"type","values":{"stringValue":["CUSTOMER"]}}${commissionName}]}`;

                break;
            default:
                url = `${apiUrl}/${resource}/search/paging?pageNr=${page}&pageSize=${perPage}`;
        }
        return httpClient(url, { credentials: "include" })
            .then(({ headers, json }) => ({
                data: json.bundles,
                total: json?.maxItemCount,
            }))
            .catch((error) => Promise.reject(error));
    },

    getOne: (resource, params) => {
        const { id } = params;
        let merchantId = `{"attribute":"merchantId","values":{"stringValue":["${id}"]}}`;
        let payoutId = `{"attribute":"payoutId","values":{"stringValue":["${id}"]}}`;
        let url = "";
        switch (resource) {
            case "merchants":
                url = `${apiUrl}/${resource}/search/paging?pageNr=1&pageSize=1&queryCriteria={"order":[{"attribute":"id","ascending":true}],"filter":[${merchantId}]}`;
                break;
            case "payouts":
                url = `${apiUrl}/${resource}/search/paging?pageNr=1&pageSize=1&queryCriteria={"order":[{"attribute":"id","ascending":true}],"filter":[${payoutId}]}`;
                break;
            case "payments":
            case "payout": {
                url = `${apiUrl}/pricings/${id}`;
                break;
            }
            case "balance":
            case "merchant/account":
            case "paymentProviders":
                url = `${apiUrl}/${resource}/`;
                break;

            default:
                url = `${apiUrl}/${resource}/search/paging?pageNr=1&pageSize=1`;
        }
        if (resource === "balance") {
            return httpClient(url, { credentials: "include" })
                .then(({ headers, json }) => ({
                    data: { ...json, id: json?.merchantId },
                    total: json?.maxItemCount,
                }))
                .catch((error) => Promise.reject(error));
        } else if (
            resource === "merchant/account" ||
            resource === "paymentProviders"
        ) {
            return httpClient(url, { credentials: "include" })
                .then(({ headers, json }) => ({
                    data: json,
                }))
                .catch((error) => Promise.reject(error));
        } else if (resource === "payments" || resource === "payout") {
            return httpClient(url, { credentials: "include" })
                .then(({ headers, json }) => ({
                    data: { ...json, id: json?.id.uuid, pricingId: json?.id },
                }))
                .catch((error) => Promise.reject(error));
        } else {
            return httpClient(url, { credentials: "include" })
                .then(({ headers, json }) => ({
                    data: json?.bundles[0],
                    total: json?.maxItemCount,
                }))
                .catch((error) => Promise.reject(error));
        }
    },

    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ ids: params.ids }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url, { credentials: "include" }).then(({ json }) => ({
            data: json,
        }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url, { credentials: "include" }).then(
            ({ headers, json }) => ({
                data: json,
                total: json?.maxItemCount,
            })
        );
    },

    update: (resource, params) => {
        if (resource === "merchants") {
            return httpClient(`${apiUrl}/${resource}/`, {
                method: "PUT",
                withCredentials: true,
                credentials: "include",
                body: JSON.stringify({
                    activated: params.data.activated,
                    companyName: params.data.companyName,
                    contactEmail: params.data.contactEmail,
                    contactPersonFirstName: params.data.contactPersonFirstName,
                    additionalCostEnabled: params.data.additionalCostEnabled,
                    contactPersonLastName: params.data.contactPersonLastName,
                    currency: params.data.currency,
                    email: params.data.email,
                    telephone: params.data.phoneNumber,
                    supportEmail: params.data.supportEmail,
                    faxNumber: params.data.faxNumber,
                    id: {
                        uuid: params.data.id,
                        version: params.data.version,
                    },
                    name: params.data.name,
                    payoutDetails: {
                        accountName: params.data.payoutAccountName,
                        accountNumber: params.data.payoutAccountNumber,
                        acquirer: params.data.payoutAcquirer,
                        customerReference: params.data.payoutCustomerReference,
                        type: params.data.payoutType,
                    },
                    address: {
                        address: params.data.address,
                        city: params.data.city,
                        countryIso3Code: params.data.countryIso3Code,
                        postalCode: params.data.postalCode,
                        state: params.data.state,
                    },
                }),
            }).then((r) => ({ data: { id: r.status } }));
        } else if (resource === "merchant/account") {
            return httpClient(`${apiUrl}/${resource}/`, {
                method: "POST",
                withCredentials: true,
                credentials: "include",
                body: JSON.stringify(params.data),
            }).then((r) => ({ data: { id: r.status } }));
        } else if (
            resource ===
            `${params.merchantId}/${
                params.activated ? "deactivate" : "activate"
            }`
        ) {
            return httpClient(`${apiUrl}/merchants/${resource}`, {
                method: "PUT",
                withCredentials: true,
                credentials: "include",
            }).then((r) => ({ data: { id: r.status } }));
        } else if (resource === "payments" || resource === "payout") {
            const body = params.data;
            body.id = params.data.pricingId;
            return httpClient(`${apiUrl}/pricings/`, {
                method: "PUT",
                withCredentials: true,
                credentials: "include",
                body: JSON.stringify(body),
            }).then(({ json }) => ({ data: json }));
        } else {
            return httpClient(`${apiUrl}/${resource}/`, {
                method: "PUT",
                withCredentials: true,
                credentials: "include",
                body: JSON.stringify({
                    ...params.data,
                    id: params.data.userId,
                }),
            }).then((r) => ({ data: { id: r.status } }));
        }
    },

    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: "PUT",
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    create: (resource, params) => {
        if (resource === "payments") {
            return httpClient(`${apiUrl}/pricings`, {
                method: "POST",
                withCredentials: true,
                credentials: "include",
                body: JSON.stringify({ ...params.data, type: "CUSTOMER" }),
            }).then(({ json }) => ({
                data: { ...params.data, id: null },
            }));
        } else if (resource === "payout") {
            return httpClient(`${apiUrl}/pricings`, {
                method: "POST",
                withCredentials: true,
                credentials: "include",
                body: JSON.stringify({ ...params.data, type: "MERCHANT" }),
            }).then(({ json }) => ({
                data: { ...params.data, id: null },
            }));
        } else if (resource === "register") {
            delete params?.data?.confirm_password;
            return httpClient(`${apiUrl}/merchant/register`, {
                method: "POST",
                withCredentials: true,
                credentials: "include",
                body: JSON.stringify({ ...params.data, langKey: "en" }),
            }).then((r) => ({
                data: { ...params.data, id: null },
                status: r.status,
            }));
        } else {
            return httpClient(`${apiUrl}/${resource}`, {
                method: "POST",
                withCredentials: true,
                credentials: "include",
                body: JSON.stringify(params.data),
            }).then(({ json }) => ({
                data: { ...params.data, id: null },
            }));
        }
    },

    delete: (resource, params) => {
        if (resource === "payments" || resource === "payout") {
            return httpClient(`${apiUrl}/pricings/${params.id}`, {
                method: "DELETE",
                credentials: "include",
                withCredentials: true,
            }).then((r) => ({ data: r.status }));
        } else if (resource === "payouts") {
            return httpClient(`${apiUrl}/payouts/${params.id}`, {
                method: "DELETE",
                credentials: "include",
                withCredentials: true,
            }).then((r) => ({ data: r.status }));
        } else {
            httpClient(`${apiUrl}/${resource}/${params.id}`, {
                method: "DELETE",
                credentials: "include",
                withCredentials: true,
            }).then(({ json }) => ({ data: json }));
        }
    },
    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: "DELETE",
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },
};
