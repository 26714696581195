import * as React from "react";
import {Datagrid, EditButton, EmailField, List, Pagination, TextField, TextInput} from 'react-admin';
import {ActivateButton} from "./ActivateButton";
import {ThemeProvider} from '@material-ui/core/styles';
import {themeMUI} from "./styles";
import TranslatedField from "../../components/TranslatedField";


const merchantFilters = [
    <TextInput source="login"   alwaysOn/>,
]
const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;


export const MerchantsList = props => {

    return (
        <ThemeProvider theme={themeMUI()}>
            <List {...props}
                  filters={merchantFilters}
                  pagination={<PostPagination/>}
                  sort={{field: 'login', order: 'ASC'}}

            >
                <Datagrid>
                    <TextField source="login"/>
                    <TranslatedField namespace={"resources.activated"}  source="activated"/>
                    <TextField source="companyName"/>
                    <TextField source="countryIso3Code" label={'resources.merchants.fields.countryCode'}/>
                    <EmailField source="email"/>
                    <EditButton
                        style={ {border : 'none', backgroundColor: 'transparent'} }
                        label="resources.merchants.fields.profile"
                        basePath="/merchants"
                        record={'id'}
                    />
                    <ActivateButton
                        variant={'text'}
                        basePath="/merchants"
                        record={props}/>

                </Datagrid>
            </List>
        </ThemeProvider>
    )
};