import * as React from "react";
import {Create, DeleteButton, required, SaveButton, SimpleForm, TextInput, Toolbar, useTranslate} from 'react-admin';
import {SectionTitle} from "../../../components/SectionTitle";
import {Box, Grid} from "@material-ui/core";
import PayoutInformationGrid from "./PayoutInformationGrid";
import {useStyles} from "./styles";


const CustomToolbar = props => {
    const classes = useStyles();
    return (
        <Toolbar {...props} className={classes.toolbar}>
            <SaveButton
                label="resources.payouts.forms.submitButton"
                redirect={'list'}
                submitOnEnter={true}
            />
            <DeleteButton undoable={false}/>

        </Toolbar>)
};


export const PayoutFormCreate = ({loading, userData, balance, ...props}) => {
    const classes = useStyles()
    const translate = useTranslate()
    return (
        <Create
            classes={{
                root: classes.editForm,
                card: classes.paper,
            }}
            title={'resources.payouts.forms.titleCreate'}
            {...props} >
            <SimpleForm
                width={"100%"}
                title={'resources.payouts.forms.titleCreate'}
                toolbar={<CustomToolbar handleSubmitWithRedirect={'list'}/>}
            >
                <Grid className={classes.titleForm}>
                    {translate('resources.payouts.forms.titleCreate')}
                </Grid>
                <Grid className={classes.root} container width={"100%"}>
                    <Grid width={"100%"}>
                        <Box className={classes.wrapper}>
                            <SectionTitle
                                className={classes.title}
                                label="resources.payouts.forms.amount"/>
                            <TextInput
                                defaultValue={balance?.balance}
                                source="amount"
                                label={false}
                                className={classes.inputMedium}
                                validate={requiredValidate}
                            />
                        </Box>
                    </Grid>
                    <PayoutInformationGrid
                        userData={userData}
                        loading={loading}
                    />
                    <Box>
                        <SectionTitle
                            className={classes.title}
                            label="resources.payouts.forms.comment"/>
                        <TextInput
                            source="comment"
                            label={false}
                            multiline={true}
                            rows={6}
                            className={classes.inputDescription}
                        />
                    </Box>
                </Grid>
            </SimpleForm>
        </Create>


    )
}
const requiredValidate = [required()];