import jsonExport from "jsonexport/dist";
import { downloadCSV } from "react-admin";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);

export function exporterCreation(permissions) {
  const exporterMap = {
    ROLE_BUSINESS_ADMIN: adminExporter,
    ROLE_MERCHANT: merchantExporter,
  };
  return exporterMap[permissions];
}

function adminExporter(orders) {
  const ordersForExport = orders.map((order) => {
    const orderForExport = {};
    const format = `YYYY-MM-DD HH:mm:ss`.trimEnd();

    orderForExport["Customer Price ID"] = order.customerPriceId;
    orderForExport["Customer Payment ID"] = order.customerPaymentId;
    orderForExport["Provider ID"] = order.paymentProviderId;
    orderForExport["Merchant Ref One"] = order.merchantRefOne;
    orderForExport["Merchant Ref Two"] = order.merchantRefTwo;
    orderForExport["Order Transaction ID"] = order.orderTransactionId;
    orderForExport["Order External Ref ID"] = order.orderExternalRefId;
    orderForExport["Links Rel"] =
      Array.isArray(order.links) && order.links[0]?.rel;
    orderForExport["Link href"] =
      Array.isArray(order.links) && order.links[0]?.href;
    orderForExport["Created At"] = dayjs.utc(order.date).add(1, 'hour').format(format);
    orderForExport["Processed Date"] = order.processedDate ? dayjs.utc(order.processedDate).add(1, 'hour').format(format): "";
    orderForExport["Customer Name"] = order.customerName;
    orderForExport["Customer Email"] = order.customerEmail;
    orderForExport["Account Number"] = order.customerPaymentAccountNumber;
    orderForExport["Account Name"] = order.customerPaymentAccountName;
    orderForExport["Currency"] = order.currency;
    orderForExport["Amount"] = order.totalAmount;
    orderForExport["Retained Fees"] = order.customerFee;
    orderForExport["Applied Service charge"] = order.appliedServiceCharge;
    orderForExport["Total Amount"] = order.amountPaid;
    orderForExport["Description"] = order.orderDescription;
    orderForExport["Payment Status"] = order.status;
    orderForExport["Provider Name"] = order.paymentProviderName;
    orderForExport["Merchant Name"] = order.merchantName;
    orderForExport["Merchant ID"] = order.merchantId;
    orderForExport["Transaction Id"] = order.id;
    orderForExport["Merchant L. Id"] = order.merchantLId;

    return orderForExport;
  });

  jsonExport(
    ordersForExport,
    {
      headers: [
        "Created At",
        "Processed Date",
        "Customer Name",
        "Customer Email",
        "Customer Price ID",
        "Customer Payment ID",
        "Account Number",
        "Account Name",
        "Currency",
        "Amount",
        "Retained Fees",
        "Applied Service charge",
        "Total Amount",
        "Description",
        "Payment Status",
        "Provider Name",
        "Provider ID",
        "Merchant Name",
        "Merchant ID",
        "Merchant L. Id",
        "Transaction Id",
        "Merchant Ref One",
        "Merchant Ref Two",
        "Order Transaction ID",
        "Order External Ref ID",
        "Links Rel",
        "Link href",
      ],
    },
    (err, csv) => {
      downloadCSV(csv, "order_report");
    }
  );
}

function merchantExporter(orders) {
  const ordersForExport = orders.map((order) => {
    const orderForExport = {};
    const format = `YYYY-MM-DD HH:mm:ss`.trimEnd();

    orderForExport["Created At"] = dayjs.utc(order.date).add(1, 'hour').format(format);
    orderForExport["Processed Date"] = order.processedDate ? dayjs.utc(order.processedDate).add(1, 'hour').format(format): "";
    orderForExport["Customer Name"] = order.customerName;
    orderForExport["Customer Email"] = order.customerEmail;
    orderForExport["Account Number"] = order.customerPaymentAccountNumber;
    orderForExport["Account Name"] = order.customerPaymentAccountName;
    orderForExport["Currency"] = order.currency;
    orderForExport["Amount"] = order.totalAmount;
    orderForExport["Retained Fees"] = order.customerFee;
    orderForExport["Applied Service charge"] = order.appliedServiceCharge;
    orderForExport["Total Amount"] = order.amountPaid;
    orderForExport["Description"] = order.orderDescription;
    orderForExport["Payment Status"] = order.status;
    orderForExport["Provider Name"] = order.paymentProviderName;
    orderForExport["Merchant Name"] = order.merchantName;
    orderForExport["Merchant ID"] = order.merchantId;
    orderForExport["Order External Ref ID"] = order.orderExternalRefId;
    orderForExport["Transaction Id"] = order.id;

    return orderForExport;
  });

  jsonExport(
    ordersForExport,
    {
      headers: [
        "Created At",
        "Processed Date",
        "Customer Name",
        "Customer Email",
        "Account Number",
        "Account Name",
        "Currency",
        "Amount",
        "Retained Fees",
        "Applied Service charge",
        "Total Amount",
        "Description",
        "Payment Status",
        "Provider Name",
        "Merchant Name",
        "Merchant ID",
        "Order External Ref ID",
        "Transaction Id",
      ],
    },
    (err, csv) => {
      downloadCSV(csv, "order_report");
    }
  );
}
