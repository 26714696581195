import {makeStyles} from "@material-ui/core/styles";
import palette from "../../../styles/palette";

export const useStyles = makeStyles((theme) => ({
    root: {
        flexDirection: 'column',
        width: '100% !important',
        color: theme.palette.text.secondary,
        "& .MuiTab-textColorInherit ": {
            fontFamily: "IBM Plex Sans",
            fontSize: "1.6rem",
            textTransform: 'capitalize',
            paddingRight: theme.spacing(10)
        },
    },
    editForm: {
        width: "60%",
    },
    paper: {
        width: "60%",
        boxShadow: 'none',
        marginTop: theme.spacing(2),
        paddingTop: 0,
        borderRadius: 5,
        border: "1px solid #0AA5B7",
    },
    titleForm: {
        width: "100%",
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        borderBottom: '1px solid #0AA5B7',
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        fontWeight: 700,
        color: palette.primary.main,
        fontSize: '1.5rem',
    },

    boxWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    inputMax: {
        maxWidth: "100%",
    },
    inputMedium: {
        maxWidth: "100%",
    },
    title: {
        fontSize: '1.125rem',
        fontWeight: 700,
        color: palette.primary.main,
    },
    wrapper: {
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    wrapperGrid: {
        width: '70%',
    },
    rowFieldWrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
    },
    button: {
        borderRadius: 20,
        backgroundColor: palette.primary.main,
        color: "#FFF",
        marginRight: theme.spacing(1),
        padding: theme.spacing(1, 2.5),
        '&:hover': {
            backgroundColor: 'rgb(7, 115, 128)',
        },
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: `${theme.palette.primary.light} !important`,
        borderRadius: " 0px 0px 5px 5px",
    },
    inputDescription: {
        width: "100%",
        marginRight: theme.spacing(2),
        "& .MuiFilledInput-input": {
            backgroundColor: theme.palette.primary.light,
        },
        "& .MuiFilledInput-root": {
            marginRight: theme.spacing(2),
            backgroundColor: theme.palette.primary.light,
            border: "1px solid #0AA5B7",
        },
        "& .MuiInputBase-input": {
            backgroundColor: theme.palette.primary.light,
        },
    },
}));
