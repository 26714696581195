import {makeStyles} from "@material-ui/core/styles";
import palette from "../../styles/palette";

export const useStyles = makeStyles(theme => ({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: `${theme.palette.primary.light} !important`,
        borderRadius: " 0px 0px 5px 5px",
        "& .MuiButton-containedPrimary": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.background.default,
            borderRadius: "20px",
        }
    },
    paper: {
        display: 'flex',
        justifyContent: 'center',
        boxShadow: 'none',
        paddingTop: 0,
        alignItems: 'center',
    },
    wrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingTop:theme.spacing(2),
    },
    titleForm: {
        width: "100%",
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        borderBottom: '1px solid #0AA5B7',
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(4),
        fontWeight: 700,
        color: palette.primary.main,
        fontSize: '1.5rem',
    },
    form: {
        width: "100%",
        maxWidth: 1450,
        marginLeft: theme.spacing(9),
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
        border: " 1px solid #0AA5B7",
        borderRadius: "5px",
    },
    inputMedium: {
        width: 560,
        marginRight: theme.spacing(2),
    },
    leftGap: {
        marginLeft: theme.spacing(2),
    },
    text: {
        width: "60%",
        paddingBottom: theme.spacing(5.5),
        fontSize: "1.125rem",
        fontWeight: "500",
        color: "rgba(0, 0, 0, 0.49)",
    },
    firstParagraph: {
        marginBottom: 20
    },
    title: {
        fontSize: '1.125rem',
        fontWeight: 700,
        color: palette.primary.main,
    },
    radioTitle: {
        fontSize: 18,
        color: palette.grey[750],
        marginBottom: 20,
    },
    radioContainer: {
        display: "flex",
        alignItems: "flex-start",
        marginTop: 8,
        marginBottom: 10
    },
    radioLabel: {
        width: 300,
        fontWeight: 600,
        alignSelf: "center",
    },
    radioBtn: {
        alignItems: "flex-start"
    },
    descriptionWrapper: {
        flex: 1
    },
    radioLabelDescription: {
        width: "80%",
        fontSize: 16,
        marginBottom: 10
    },
    sampleLink: {
        width: "80%",
        color: "rgba(0, 0, 0, 0.49)",
    }
}));