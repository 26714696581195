import * as React from 'react';
import {email, required, SelectInput, TextInput} from 'react-admin';
import {Box, Grid} from "@material-ui/core";
import {SectionTitle} from "../../../components/SectionTitle";
import {useStyles} from "./styles";
import {allCountries, paymentType, supportedCurrencies} from "../../../helpers/choices";

const ViewDetailsForm = (props) => {
    const classes = useStyles();

    return (
        <Grid container direction='row' justifyContent='space-between'>
            <Box width={'70%'}>
                <SectionTitle
                    className={classes.title}
                    label="resources.merchants.forms.basicInfo"/>
                <Box className={classes.wrapper}>
                    <TextInput
                        resource={'merchants'}
                        source='contactPersonFirstName'
                        className={classes.inputMedium}
                        validate={requiredValidate}
                    />
                    <TextInput
                        resource={'merchants'}
                        source="contactPersonLastName"
                        className={classes.inputMedium}
                        validate={requiredValidate}
                    />
                </Box>
                <SectionTitle
                    className={classes.title}
                    label="resources.merchants.forms.companyInfo"/>
                <Box className={classes.wrapper}>
                    <TextInput
                        source="companyName"
                        resource={'merchants'}
                        className={classes.inputMedium}
                        validate={requiredValidate}
                    />
                    <TextInput
                        resource={'merchants'}
                        source="address"
                        className={classes.inputMedium}
                        validate={requiredValidate}
                    />
                </Box>
                <Box className={classes.wrapper}>
                    <Box width={'50%'} mr={2}>
                        <TextInput
                            margin={'dense'}
                            resource={'merchants'}
                            source="state"
                            className={classes.inputMedium}
                            validate={requiredValidate}
                        />
                    </Box>
                    <Box className={classes.fieldWrapper}>
                        <TextInput
                            resource={'merchants'}
                            source="city"
                            margin={'dense'}
                            className={classes.inputSmall}
                            validate={requiredValidate}
                        />
                        <TextInput
                            resource={'merchants'}
                            source="postalCode"
                            margin={'dense'}
                            className={classes.postcode}
                            validate={requiredValidate}
                        />
                    </Box>
                </Box>
                <Box className={classes.wrapper}>
                    <SelectInput
                        resource={'merchants'}
                        source="countryIso3Code"
                        choices={allCountries}
                        optionText="name"
                        optionValue="code"
                        className={classes.inputMedium}
                        validate={requiredValidate}
                    />
                    <SelectInput
                        resource={'merchants'}
                        source="currency"
                        choices={supportedCurrencies}
                        optionText="fullDescription"
                        optionValue="code"
                        className={classes.inputMedium}
                        validate={requiredValidate}
                    />
                </Box>
                <SectionTitle
                    className={classes.title}
                    label="resources.merchants.forms.contactInfo"/>
                <Box className={classes.wrapper}>
                    <TextInput
                        resource={'merchants'}
                        source="phoneNumber"
                        className={classes.inputMedium}
                        validate={requiredValidate}
                    />
                    <TextInput
                        resource={'merchants'}
                        source="faxNumber"
                        className={classes.inputMedium}
                    />
                </Box>
                <Box className={classes.wrapper}>
                    <TextInput
                        resource={'merchants'}
                        type="email"
                        source="email"
                        validation={{email: true}}
                        className={classes.inputMedium}
                        validate={[required(), email()]}
                    />

                    <TextInput
                        resource={'merchants'}
                        source="supportEmail"
                        className={classes.inputMedium}
                        validate={[email()]}
                    />
                </Box>
            </Box>
            <Box>
                <SectionTitle
                    className={classes.title}
                    label="resources.merchants.forms.paymentInfo"/>
                <SelectInput
                    resource={'merchants'}
                    source="payoutType"
                    choices={paymentType}
                    optionText="value"
                    optionValue="value"
                    className={classes.inputMedium}
                />
                <Separator/>
                <TextInput
                    resource={'merchants'}
                    source="payoutAcquirer"
                    className={classes.inputMedium}
                />
                <Separator/>
                <TextInput
                    resource={'merchants'}
                    source="payoutAccountNumber"
                    className={classes.inputMedium}
                />
                <Separator/>
                <TextInput
                    resource={'merchants'}
                    label={"Account Name"}
                    source="payoutAccountName"
                    className={classes.inputMedium}
                />
                <Separator/>
                <TextInput
                    resource={'merchants'}
                    source="payoutCustomerReference"
                    label={'Customer Reference'}
                    className={classes.inputMedium}
                />
                <Separator/>
            </Box>
        </Grid>


    )
};
const requiredValidate = [required()];
const Separator = () => <Box pt="1em"/>;
export default ViewDetailsForm;