import * as React from 'react';
import {Route} from 'react-router-dom';
import ApiSettings from "./views/ApiSettings/ApiSettings";
import MerchantForm from "./views/EditProfile/merchant/merchantForm";
import AdminForm from "./views/EditProfile/admin/adminForm";
import CreateForm from "./views/createForm/CreateForm";
import ForgotPassword from "./views/ForgotPassword";
import {
    ActivationNote,
    RegistrationSaved,
    ResetPasswordApply,
    ResetPasswordFailed,
    ResetPasswordSuccess
} from "./views/createForm/RegistrationSaved";
import ResetPassword from "./views/ResetPassword";




export default [
    <Route key="my-profile" path="/my-profile" render={() => <MerchantForm/>}/>,
    <Route key="/create-profile" path="/create-profile" render={() => <CreateForm/>}/>,
    <Route key="/forgot-password" path="/forgot-password" render={() => <ForgotPassword/>}/>,
    <Route key="/registration-saved" path="/registration-saved" render={() => <RegistrationSaved/>}/>,
    <Route key="reset-password-saved" path="/reset-password-saved" render={() => <ResetPasswordSuccess/>}/>,
    <Route key="reset-password-saved" path="/reset-password-apply" render={() => <ResetPasswordApply/>}/>,
    <Route key="reset-password-saved" path="/reset-password-error" render={() => <ResetPasswordFailed/>}/>,
    <Route  path="/reset/finish" render={() => <ResetPassword/>}/>,
    <Route  path="/activate" render={() => <ActivationNote/>}/>,
    <Route exact path="/edit-password" render={() => <AdminForm/>}/>,
    <Route exact path="/api-settings" render={() => <ApiSettings/>}/>,

];
