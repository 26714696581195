

export default [
    {
        id: 'min',
        label: 'resources.payments.grid.min',
        key: 'Min',
        type: 'string',
        align: "left",
    },
    {
        id: 'max',
        label: 'resources.payments.grid.max',
        key: 'Max',
        type: 'string',
        align: "left",
    },
    {
        id: 'fixed',
        label: 'resources.payments.grid.fixed',
        key: 'Fixed',
        type: 'string',
        align: "left",
    },
    {
        id: 'percentage',
        label: 'resources.payments.grid.percentage',
        key: 'Percentage',
        type: 'string',
        align: "left",
    },
];
