import React from "react";
import axios from 'axios';

function refreshPage() {
    window.location.reload(false);
}
export const authProvider = {

    login: ({username, password, rememberMe}) => {
        const fordData = new URLSearchParams()
        fordData.append('j_username', username)
        fordData.append('j_password', password)
        fordData.append('remember-me', rememberMe)
        fordData.append('submit', 'true')

        const request = ({
            method: 'POST',
            withCredentials: true,
            data: fordData,
            headers: new Headers({'Content-Type': 'application/x-www-form-urlencoded'}),
        });
        return axios(`${window._env_.REACT_APP_API_HOST}` + '/merchant/api/authentication', request).then(response => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            localStorage.setItem('isAuth', true);
            return Promise.resolve();
        })
            .catch(() => {
                throw new Error('Network error')
            });
    },

    logout: () => {
        const request = (
            {
                withCredentials: true,
                method: 'POST'
            });

        return axios(`${window._env_.REACT_APP_API_HOST}` + '/merchant/api/logout', request).then(response => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            localStorage.removeItem('isAuth');
            localStorage.removeItem('username');
            return Promise.resolve('/login');
        })
    },

    checkAuth: () => localStorage.getItem('isAuth') === 'true' ? Promise.resolve() : Promise.reject(),
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.setItem('isAuth', false);
            return Promise.reject();
        }
        return Promise.resolve();
    },
    getPermissions: () => {
        const request = ({
            method: 'GET',
            withCredentials: true,
            headers: ({'Content-Type': 'application/json'}),
        });

        return axios(`${window._env_.REACT_APP_API_HOST}` + '/merchant/api/account', request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    return Promise.reject();
                }
                if (response.data.activated === false) {
                    localStorage.setItem('isAuth', false);
                    window.location.replace('#/login')
                    return Promise.reject()
                }
                localStorage.setItem("langKey", response.data.langKey);
                localStorage.setItem("username", response.data.login);
                return Promise.resolve(response.data.roles[0])
            }).catch(error => {
                return Promise.resolve([]);
            });

    },
}


