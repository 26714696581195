import * as React from 'react';
import {Checkbox} from '@material-ui/core';
import {FieldRenderProps} from 'react-final-form';


class CheckboxWrapper extends React.Component<FieldRenderProps<any>> {
    render() {
        let {
            input: {checked, name, onChange, ...restInput},
            meta,
            ...rest
        } = this.props;
        return (
            <Checkbox
                {...rest}
                name={name}
                inputProps={restInput}
                onChange={onChange}
                checked={checked}
            />
        );
    }
}

export default CheckboxWrapper;