import {makeStyles} from "@material-ui/core/styles";
import palette from "../../../styles/palette";

export const useStyles = makeStyles((theme) => ({
    root: {
        width: '100% !important',
        color: theme.palette.text.secondary,
        paddingTop:theme.spacing(4),
    },
    editForm: {
        marginTop: theme.spacing(3),
        width: "80%",
    },
    paper: {
        boxShadow: 'none',
        marginTop: theme.spacing(2),
        paddingTop: 0,
        borderRadius: 5,
        border: "1px solid #0AA5B7",
    },
    iterator:{display: "flex", flexDirection: "row",transition:"none"},
    iteratorDone:{display: "flex", opacity:"1 !important",transform:"translateX(0) !important"},
    button: {
        height: 40,
        width: "fit-content",
        fontSize: 12,
        fontFamily: "IBM Plex Sans" + " !important",
        fontWeight: "700" + " !important",
        borderRadius: "20px" + " !important",
        color: theme.palette.primary.main + ' !important',
        borderColor: theme.palette.primary.main + ' !important',
    },
    inputMedium: {
        width: "100%",

        "& .MuiFilledInput-input": {
            marginRight: theme.spacing(2),
            backgroundColor: theme.palette.primary.light,
            borderRadius: "15px 15px 0px 0px"
        },
        "& .MuiFilledInput-root": {
            marginRight: 16,
            backgroundColor: theme.palette.primary.light,
            borderRadius: "15px 15px 0px 0px"
        },
        "& .MuiInputBase-root": {
            marginRight: theme.spacing(2),
            backgroundColor: theme.palette.primary.light,
            borderRadius: "15px 15px 0px 0px"
        },
    },
    title: {
        fontSize: '1.125rem',
        fontWeight: 700,
        color: palette.primary.main,
    },
    tab:{
       color: theme.palette.primary.main,
        fontSize:"1.125rem",
        textTransform:"none",
        paddingLeft:theme.spacing(8),
        paddingRight:theme.spacing(5),
    },
    wrapper: {
        marginLeft: theme.spacing(3),
        width: "100%",
        maxWidth: 900,
        justifyContent: "space-between",
        display: 'flex',
        flexDirection: 'row',
    },
    fieldGap: {
        width: "95%",
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: `${theme.palette.primary.light} !important`,
        borderRadius: " 0px 0px 5px 5px",
        "& .MuiButton-containedPrimary": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.background.default,
            borderRadius: "20px",
        }
    },
    inputDescription: {
        width: "100%",
        marginRight: theme.spacing(2),

    },
}));
