import * as React from 'react';
import {Layout} from 'react-admin';
import AppBar from './AppBar';
import Menu from './Menu';



const StyledLayout = props => {

    return <Layout {...props} appBar={AppBar} menu={Menu}/>;
};
export default StyledLayout